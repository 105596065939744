import { ModalHeader, Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

interface DialogProps {
  show: boolean;
  handleClose: () => void;
  title?: string;
  children?: JSX.Element | Array<JSX.Element>;
}

export function ModalDialog({
  title,
  show,
  children,
  handleClose,
}: DialogProps) {
  return (
    <Modal className='' size='xl' show={show} centered style={{ zIndex: 1050 }}>
      <ModalHeader
        className='fs-3'
        style={{ fontFamily: 'NanumSquareExtraBold' }}
        closeButton
        onHide={handleClose}
      >
        {title}
      </ModalHeader>
      {children}
    </Modal>
  );
}

interface ModalSpinnerProps {
  show: boolean;
}

export function ModalSpinner({ show }: ModalSpinnerProps) {
  return (
    <Modal
      className='d-flex justify-content-center'
      size='sm'
      show={show}
      centered
      style={{ zIndex: 1050 }}
    >
      <Spinner
        className='m-2'
        animation='border'
        variant='secondary'
        style={{
          width: '50px',
          height: '50px',
        }}
      />
    </Modal>
  );
}
