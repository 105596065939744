import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import API, { createConnection } from 'comm/MQTTApi';

interface MQTTProviderProps {
  children: React.ReactNode;
}

interface MQTTContextValue {
  api: API;
  isConnected: boolean;
}

const MQTTContext = createContext<MQTTContextValue | null>(null);

export const MQTTProvider: React.FC<MQTTProviderProps> = ({ children }) => {
  const [api] = useState<API>(new API(createConnection()));
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    api._client.on('connect', () => setIsConnected(true));
  }, [api]);

  console.log({ MQTTProvider: isConnected });

  const value = useMemo(() => ({ api, isConnected }), [api, isConnected]);

  return <MQTTContext.Provider value={value}>{children}</MQTTContext.Provider>;
};

export const useMQTTContext = () => {
  const context = useContext(MQTTContext);
  if (!context) {
    throw new Error('useMQTTContext must be used within a MQTTProvider');
  }
  return context;
};
