export * from './OnOffTimeConfig';

export function RenderOnOff({ onoff }: { onoff?: string }) {
  function OnOffTextColor(onoff?: string) {
    switch (onoff) {
      case 'ON':
        return 'text-red-500';
      case 'OFF':
        return 'text-gray-500';
      default:
        return '';
    }
  }

  return (
    <div
      className='flex items-center justify-between space-y-1 text-lg p-2 border-y-2 bg-slate-200'
      style={{ fontFamily: 'NanumSquareBold' }}
    >
      <div>ON / OFF</div>
      <div className={`${OnOffTextColor(onoff)}`}>{onoff}</div>
    </div>
  );
}

export function RenderOperationMode({ opmode }: { opmode?: string }) {
  function OpmodeColor(opmode?: string) {
    switch (opmode) {
      case '강제점등':
        return 'text-blue-500';
      case '강제소등':
        return 'text-gray-500';
      case '자동운전':
        return 'text-yellow-500';
      default:
        return '';
    }
  }

  return (
    <div
      className='flex items-center justify-between space-y-1 text-lg p-2 border-y-2 bg-slate-200'
      style={{ fontFamily: 'NanumSquareBold' }}
    >
      <div>운전 모드</div>
      <div className={`${OpmodeColor(opmode)}`}>{opmode}</div>
    </div>
  );
}

interface LabeledValueProps {
  label: string;
  labelSize?: string;
  value?: string;
  valueSize?: string;
}

export function LabeledValue({
  label,
  labelSize,
  value,
  valueSize,
}: LabeledValueProps) {
  return (
    <div
      className='flex items-center justify-between p-2 border-y-2 bg-slate-200'
      style={{ fontFamily: 'NanumSquareBold' }}
    >
      <div className={`${labelSize || 'text-xl'} whitespace-nowrap w-full`}>
        {label}
      </div>
      <div
        className={`${
          valueSize || 'text-lg'
        } whitespace-nowrap text-ellipsis text-gray-500 waving-hand`}
      >
        {value}
      </div>
    </div>
  );
}
