import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useAuth } from 'auth/AuthProvider';
import IconPerson from 'assets/icons/id.png';
import IconLogout from 'assets/icons/box-arrow-right.svg';

interface HeaderProps {
  title: string;
}

function Header({ title }: HeaderProps) {
  const auth = useAuth();
  const expand = 'sm';

  return (
    <>
      <Navbar key={expand} bg='light' expand={expand} className=''>
        <Container fluid>
          <Navbar.Brand
            className='fs-5 ms-lg-5 col-6'
            style={{
              fontFamily: 'NanumSquareExtraBold',
              color: 'Grey',
            }}
          >
            {title}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement='end'
            className='w-75'
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src={IconPerson} alt='' />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className='justify-content-end flex-grow-1 pe-3 align-baseline'>
                <Nav.Link className='disabled'>{auth.user.username}</Nav.Link>
                <Nav.Link className='disabled'>({auth.user.userid})</Nav.Link>
                <Nav.Item
                  className='mt-3 mt-lg-0 ms-lg-3 btn rounded border flex items-center space-x-2'
                  onClick={() => {
                    auth.signout(() => {});
                  }}
                >
                  <img className='' src={IconLogout} alt='' />
                  <div className='whitespace-nowrap'>로그아웃</div>
                </Nav.Item>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
