import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import LabeledRange from './LabeledRange';
import IconDimming from '../../assets/icons/light_mode_black_24dp.svg';

export interface OperationModeConfig {
  mode: string;
  dimming: number;
}

export interface OperationModeConfigViewProps {
  values: OperationModeConfig;
  onChange: (config: OperationModeConfig) => void;
  onSubmit: () => void;
}

export function StrMode2Num(mode: string): number {
  switch (mode) {
    case '자동운전':
      return 0;
    case '강제점등':
      return 1;
    case '강제소등':
      return 2;
    default:
      return -1;
  }
}

function OperationModeConfigView({
  values,
  onChange,
  onSubmit,
}: OperationModeConfigViewProps) {
  const radios = [
    { name: '자동 운전', value: '자동운전' },
    { name: '수동 OFF', value: '강제소등' },
    { name: '수동 ON', value: '강제점등' },
  ];

  return (
    <div className='px-4 py-2'>
      <div className='fs-5 fw-bold'>운영 모드</div>
      <form onSubmit={(value) => console.log(value)}>
        <div className='d-flex mt-3'>
          <ButtonGroup className='w-100'>
            {radios.map((radio, idx) => (
              <ToggleButton
                className='fs-6 text-nowrap'
                key={idx}
                id={`radio-${idx}`}
                type='radio'
                variant='outline-success'
                name='mode'
                value={radio.value}
                checked={values.mode === radio.value}
                onChange={(e) => onChange({ ...values, mode: e.target.value })}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>

          <LabeledRange
            className='ms-3'
            label={<img src={IconDimming} alt='' />}
            min={0}
            max={100}
            value={values.dimming}
            onChange={(e) => onChange({ ...values, dimming: e })}
            disabled={values.mode !== '강제점등'}
          />
        </div>
        <div className='d-flex flex-column align-items-center mt-3'>
          <Button className='col-1' onClick={onSubmit}>
            적용
          </Button>
        </div>
      </form>
    </div>
  );
}

export default OperationModeConfigView;
