import { Site } from './SiteModel';
import YeoncheonGunmiriLightPoles from './Yeoncheon-Gumiri-LightPoles';

const YeoncheonGumiri: Site = {
  name: '연천군 구미리',
  center: {
    lat: 37.994838628082405,
    lng: 126.95652260414374,
  },

  lightPoles: YeoncheonGunmiriLightPoles,

  panelBoards: [
    {
      phoneNumber: '012-2004-4269',
      panelNumber: 5,
      name: '5번판넬',
      description: '',
      location: '경기 연천군 백학면 학곡리 238-2',
      position: {
        lat: 37.9889054 + 0.0002,
        lng: 126.9481724,
      },
      lightPoles: YeoncheonGunmiriLightPoles.slice(0, 19),
    },
    {
      phoneNumber: '012-2004-4476',
      panelNumber: 6,
      name: '6번판넬',
      description: '',
      location: '경기 연천군 백학면 학곡리 991-4',
      position: {
        lat: 37.9932931,
        lng: 126.954984 - 0.0002,
      },
      lightPoles: YeoncheonGunmiriLightPoles.slice(19, 34),
    },
    {
      phoneNumber: '012-2004-8505',
      panelNumber: 7,
      name: '7번판넬',
      description: '',
      location: '경기 연천군 백학면 구미리 산 64-6',
      position: {
        lat: 37.996169 + 0.00005,
        lng: 126.9574753 - 0.0002,
      },
      lightPoles: YeoncheonGunmiriLightPoles.slice(34, 53),
    },
    {
      phoneNumber: '012-2005-2476',
      panelNumber: 8,
      name: '8번판넬',
      description: '',
      location: '경기 연천군 백학면 구미리 13-1',
      position: {
        lat: 38.0007863,
        lng: 126.9608217 + 0.0002,
      },
      lightPoles: YeoncheonGunmiriLightPoles.slice(53, 76),
    },
  ],
};

export default YeoncheonGumiri;
