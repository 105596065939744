import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import IconPanelBoardOn from '../assets/panelboard/icon_on.png';
import IconPanelBoardOff from '../assets/panelboard/icon_off.png';
import IconPanelBoardError from '../assets/panelboard/icon_error.png';
import { PanelBoard } from '../sites/SiteModel';
import { usePanelBoardValue } from '../context/PanelBoardContext';

interface PanelBoardListProp {
  panelBoards: PanelBoard[];
  selectedIndex: number;
  onSelect: (index: number) => void;
  onSelectDoubleClick: (index: number) => void;
}

function PanelBoardList(props: PanelBoardListProp) {
  const statusList = usePanelBoardValue();

  return (
    <ListGroup
      className='bg-light rounded-3 shadow-lg h-100'
      style={{ overflow: 'auto', height: 'auto', width: 'auto' }}
    >
      {props.panelBoards.map((x, index) => (
        <ListGroupItem
          variant='secondary'
          className=''
          style={{
            fontFamily: 'NanumSquareBold',
          }}
          key={x.position.lat + x.position.lng}
          active={index === props.selectedIndex}
          action
          onClick={() => {
            props.onSelect(index);
          }}
          onDoubleClick={() => {
            props.onSelectDoubleClick(index);
          }}
        >
          <div className='d-flex flex-row align-items-center me-5'>
            <img
              // src={IconPanelBoardOn}
              src={
                statusList.length === props.panelBoards.length
                  ? statusList[index].onoff === 'ON'
                    ? IconPanelBoardOn
                    : statusList[index].onoff === 'OFF'
                    ? IconPanelBoardOff
                    : IconPanelBoardError
                  : IconPanelBoardError
              }
              alt=''
              width={60 * 0.5}
            />
            {/* <span className='ms-3 text-nowrap fs-2'>{x.panelNumber}</span> */}
            <div className='ms-3 text-nowrap fs-5'>{x.name}</div>
            <div style={{ margin: '1em' }}>{x.description}</div>
          </div>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}
export default PanelBoardList;
