import * as mqttjs from 'precompiled-mqtt';

let BROKER_URL = '';
if (process.env.NODE_ENV === 'development') {
  BROKER_URL = 'ws://localhost:9001/ws';
} else if (process.env.NODE_ENV === 'production') {
  if (window.location.protocol === 'http:') {
    BROKER_URL = 'ws://' + window.location.hostname + ':8080/ws';
  } else if (window.location.protocol === 'https:') {
    BROKER_URL = 'wss://' + window.location.hostname + ':8081/ws';
  }
}

// const BROKER_URL = process.env.REACT_APP_MQTT_BROKER_URL || '';
const BROKER_USERNAME = process.env.REACT_APP_MQTT_BROKER_USERNAME || '';
const BROKER_PASSWORD = process.env.REACT_APP_MQTT_BROKER_PASSWORD || '';
// const MQTT_TEST_WSS_UNATHENTICATED = 'wss://test.mosquitto.org:8081/mqtt';
// const MQTT_TEST_WSS_ATHENTICATED = 'wss://test.mosquitto.org:8091/mqtt';
// const MQTT_TEST_WSS_ATHENTICATED_OPTIONS = {
//   reconnectPeriod: 10 * 1000,
//   connectTimeout: 5 * 1000,
//   username: 'rw',
//   password: 'readwrite',
// };

export function createConnection(): mqttjs.MqttClient {
  const client = mqttjs.connect(BROKER_URL, {
    clientId: 'webapp-' + Math.random(),
    username: BROKER_USERNAME,
    password: BROKER_PASSWORD,
  });
  return client;
}
