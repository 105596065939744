import IconUpdate from '../assets/icons/update.png';

interface StatusUpdateButtonProps {
  caption?: string;
  onClick: () => void;
}

export default function StatusUpdateButton({
  caption,
  onClick,
}: StatusUpdateButtonProps) {
  return (
    <button className='btn btn-light rounded shadow' onClick={onClick}>
      <img src={IconUpdate} alt='' />
      {caption ? <div className='mt-2'>{caption}</div> : null}
    </button>
  );
}
