import { useState, useEffect, useCallback } from 'react';
import Header from 'components/Header';
import PanelBoardList from 'components/PanelBoardList';
import { ModalDialog, ModalSpinner } from 'components/ModalDialog';
import PanelBoardConfig from 'components/PanelBoardConfig';
import PanelBoardDetail from 'components/PanelBoardDetail';
import ELBListView from 'components/ELBList';
import LightOnOff from 'components/LightOnOff';
import YeoncheonGumiri from 'sites/Yeoncheon-Gumiri';
import {
  LightPoleStatus,
  PanelBoardStatus,
  ParsePanelBoardStatus,
} from 'libs/Protocol/Protocol';
import { LightPole } from 'sites/SiteModel';
import { toast } from 'react-toastify';
import StatusUpdateButton from 'components/StatusUpdateButton';
import KakaoMapReact from 'maps/KakaoMapReact';
import { usePanelBoardState } from 'context/PanelBoardContext';
import { useMQTTContext } from 'context/MQTTProvider';

function UpdateLightPoleStatus(
  PanelBoardLightPoles: LightPole[],
  listAll: LightPoleStatus[],
  listUpdated: LightPoleStatus[]
): LightPoleStatus[] {
  if (PanelBoardLightPoles.length === 0) {
    return listAll;
  }
  const panelboardLightPoleStartNumber = PanelBoardLightPoles[0].number;
  const panelboardLightPoleLength = PanelBoardLightPoles.length;

  const length =
    listUpdated.length < panelboardLightPoleLength
      ? listUpdated.length
      : panelboardLightPoleLength;

  const result = [...listAll];
  for (let i = 0; i < length; i++) {
    result[i + panelboardLightPoleStartNumber - 1].status =
      listUpdated[i].status;
  }
  return result;
}

const panelBoards = YeoncheonGumiri.panelBoards;
const lightPoles = YeoncheonGumiri.lightPoles;

function Home() {
  const [panelBoardIndex, setPanelBoardIndex] = useState(0);
  const [lightIndex, setLightIndex] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [showLightOnOffModal, setShowLightOnOffModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const { api, isConnected } = useMQTTContext();

  const [lightPoleStatus, setLightPoleStatus] = useState<LightPoleStatus[]>(
    lightPoles.map((pole) => ({
      number: pole.number,
      status: '모름',
    }))
  );
  const [statusList, setStatusList] = usePanelBoardState();

  const onMessage = useCallback(
    (notify: any) => {
      if (notify.result !== 'OK') {
        return;
      }
      const index = statusList.findIndex(
        (x) => x.phoneNumber === notify.response.header.전화번호
      );

      if (index === -1) {
        return;
      }

      let newStatusList = [...statusList];
      newStatusList[index] = ParsePanelBoardStatus(notify.response);
      setStatusList(newStatusList);
    },
    [statusList, setStatusList]
  );

  api.SetSubscription({ callback: onMessage });

  useEffect(() => {
    async function fetchData() {
      let statusList: PanelBoardStatus[] = [];
      let lightPoleStatusList: LightPoleStatus[] = [];

      for (let i = 0; i < panelBoards.length; i++) {
        try {
          const resp = await api.GetLogStatus({
            phoneno: panelBoards[i].phoneNumber,
          });

          statusList.push(resp);

          // const newPoleStatus = resp.lightPoles ?? [];
          // lightPoleStatusList = lightPoleStatusList.concat(
          //   newPoleStatus.slice(0, panelBoards[i].lightPoles.length)
          // );
          lightPoleStatusList = lightPoleStatusList.concat(
            panelBoards[i].lightPoles.map((x) => ({
              number: x.number,
              status: resp.onoff ?? 'OFF',
            }))
          );
        } catch (e) {
          console.log(e);
          statusList.push({});
          lightPoleStatusList = lightPoleStatusList.concat(
            panelBoards[i].lightPoles.map((x) => ({
              number: x.number,
              status: 'OFF',
            }))
          );
        }
      }

      setLightPoleStatus(lightPoleStatusList);
      setStatusList(statusList);
    }
    if (isConnected) {
      fetchData();
    }
  }, [api, isConnected, setStatusList]);

  if (!isConnected) {
    return <div>Connecting to Server...</div>;
  }

  return (
    <div className='vh-100 d-flex flex-column justify-content-start'>
      <ModalDialog
        title={panelBoards[panelBoardIndex].name}
        show={showModal}
        handleClose={() => setShowModal(false)}
      >
        <PanelBoardConfig
          phoneNumber={panelBoards[panelBoardIndex].phoneNumber}
          onLoading={setLoading}
          onClose={() => {
            setShowModal(false);
          }}
        />
      </ModalDialog>
      <ModalSpinner show={loading} />
      <LightOnOff
        show={showLightOnOffModal}
        handleClose={() => setShowLightOnOffModal(false)}
        status={lightPoleStatus[lightIndex].status}
        handleChange={async (status) => {
          setLoading(true);
          try {
            const resp = await api.SetLightPole({
              phoneno: lightPoles[lightIndex].phoneNumber,
              pole: lightPoles[lightIndex].number,
              onoff: status === 'ON' ? true : false,
              isAutoMode: false,
            });
            console.log(resp);
            toast.success(status === 'ON' ? '점등 성공' : '소등 성공');
            let newStatus = [...lightPoleStatus];
            newStatus[lightIndex].status = status;
            setLightPoleStatus(newStatus);
          } catch (e) {
            console.log(e);
            toast.error(status === 'ON' ? '점등 실패' : '소등 실패');
          }
          setLoading(false);
        }}
        panelBoard={panelBoards.find(
          (x) => x.phoneNumber === lightPoles[lightIndex].phoneNumber
        )}
        phoneNumber={lightPoles[lightIndex].phoneNumber}
        lightNumber={lightPoles[lightIndex].number}
      />
      <div className='position-absolute h-100 w-100'>
        <KakaoMapReact
          site={YeoncheonGumiri}
          panelBoardIndex={panelBoardIndex}
          panelBoardOnSelect={setPanelBoardIndex}
          panelBoardOnDoubleClick={(index) => {
            setShowModal(true);
          }}
          panelBoardStatus={statusList}
          lightPostIndex={lightIndex}
          lightPostOnSelect={setLightIndex}
          lightPostOnDoubleClick={(index) => {
            setShowLightOnOffModal(true);
          }}
          lightPoleStatus={lightPoleStatus}
        />
      </div>
      <div style={{ zIndex: 7 }}>
        <Header title='연천군 가로등 관제시스템' />
      </div>
      <div className='d-flex flex-grow w-100 h-100 p-2'>
        <div className='h-100' style={{ zIndex: 7 }}>
          <PanelBoardList
            panelBoards={panelBoards}
            selectedIndex={panelBoardIndex}
            onSelect={async (index) => {
              setPanelBoardIndex(index);
            }}
            onSelectDoubleClick={(index) => {
              setPanelBoardIndex(index);
              setShowModal(true);
            }}
          />
        </div>
        <div
          className='d-flex align-self-end h-auto w-100 ms-2'
          style={{ zIndex: 7 }}
        >
          <div className='bg-light w-100 rounded-3 shadow-lg p-3'>
            <PanelBoardDetail
              phoneNumber={panelBoards[panelBoardIndex].phoneNumber}
              location={panelBoards[panelBoardIndex].location}
            />
            <ELBListView elbs={statusList[panelBoardIndex]?.elbs} />
          </div>
        </div>
      </div>
      <div
        className='position-absolute w-auto p-2'
        style={{ zIndex: 7, bottom: '290px', right: '0px' }}
      >
        <StatusUpdateButton
          onClick={async () => {
            setLoading(true);

            try {
              const newStatus = await api.GetStatus({
                phoneno: panelBoards[panelBoardIndex].phoneNumber,
              });

              let newStatusList = [...statusList];
              newStatusList[panelBoardIndex] = newStatus;
              setStatusList(newStatusList);

              const newLightPoleStatus = UpdateLightPoleStatus(
                panelBoards[panelBoardIndex].lightPoles,
                lightPoleStatus,
                panelBoards[panelBoardIndex].lightPoles.map((x) => {
                  return { number: x.number, status: newStatus.onoff ?? 'OFF' };
                })
              );
              setLightPoleStatus(newLightPoleStatus);

              // const newLightPoles = newStatus.lightPoles;
              // if (newLightPoles) {
              //   const newLightPoleStatus = UpdateLightPoleStatus(
              //     panelBoards[panelBoardIndex].lightPoles,
              //     lightPoleStatus,
              //     newLightPoles
              //   );
              //   setLightPoleStatus(newLightPoleStatus);
              // }
              toast.success('업데이트 성공');
            } catch (e) {
              console.log(e);
              toast.error('업데이트 실패');
            }
            setLoading(false);
          }}
        />
      </div>
    </div>
  );
}

export default Home;
