import React, { useState } from 'react';
import { Map, CustomOverlayMap, Polyline } from 'react-kakao-maps-sdk';
import IconOn from '../assets/panelboard/icon_on.png';
import IconOnSelected from '../assets/panelboard/icon_on_s.png';
import IconOff from '../assets/panelboard/icon_off.png';
import IconOffSelected from '../assets/panelboard/icon_off_s.png';
import IconError from '../assets/panelboard/icon_error.png';
import IconErrorSelected from '../assets/panelboard/icon_error_s.png';
import LightBulbIconOn from '../assets/lightbulb/lightbulb_on.png';
import LightBulbIconOff from '../assets/lightbulb/lightbulb_off.png';
import LightBulbIconError from '../assets/lightbulb/lightbulb_error.png';
import { Site } from '../sites/SiteModel';
import { PanelBoardStatus, LightPoleStatus } from '../libs/Protocol/Protocol';
import { usePanelBoardValue } from 'context/PanelBoardContext';

interface CustomMarkerProps {
  index: number;
  position: {
    lat: number;
    lng: number;
  };
  image: {
    source: string;
    width: number;
    height: number;
    scale: {
      base: number;
      mouseOver: number;
      selected: number;
    };
  };
  selected: boolean;
  zIndex?: number;
  onClick: () => void;
  onDoubleClick?: () => void;
}

const CustomMarker: React.FC<CustomMarkerProps> = ({
  position,
  image,
  selected,
  zIndex,
  onClick,
  onDoubleClick,
}) => {
  const [isOver, setIsOver] = useState<boolean>(false);
  const scale = selected
    ? image.scale.selected
    : isOver
    ? image.scale.mouseOver
    : image.scale.base;

  return (
    <CustomOverlayMap
      position={position} // 마커를 표시할 위치
      clickable={true}
      zIndex={zIndex}
      yAnchor={0.5}
    >
      <div
        className='py-0'
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onMouseOver={() => setIsOver(true)}
        onMouseOut={() => setIsOver(false)}
        onDrag={() => {}}
        style={{
          width: image.width * scale,
        }}
      >
        <img
          draggable='false'
          alt=''
          src={image.source}
          width={image.width * scale}
        />
      </div>
    </CustomOverlayMap>
  );
};

function PanelBoardIcon(onoff: string, selected: boolean) {
  switch (onoff) {
    case 'ON':
      return selected ? IconOnSelected : IconOn;
    case 'OFF':
      return selected ? IconOffSelected : IconOff;
    default:
      return selected ? IconErrorSelected : IconError;
  }
}

export interface MapViewProps {
  site: Site;
  panelBoardIndex: number;
  panelBoardOnSelect: (index: number) => void;
  panelBoardOnDoubleClick: (index: number) => void;
  panelBoardStatus: PanelBoardStatus[];
  lightPostIndex: number;
  lightPostOnSelect: (index: number) => void;
  lightPostOnDoubleClick: (index: number) => void;
  lightPoleStatus: LightPoleStatus[];
}

const KakaoMapReact: React.FC<MapViewProps> = ({
  site,
  panelBoardIndex,
  panelBoardOnSelect,
  panelBoardOnDoubleClick,

  lightPostIndex,
  lightPostOnSelect,
  lightPostOnDoubleClick,
  lightPoleStatus,
}) => {
  const PanelBoardZIndex = 9;

  const latMax = site.center.lat + 0.01;
  const latMin = site.center.lat - 0.01;
  const lngMax = site.center.lng + 0.01;
  const lngMin = site.center.lng - 0.01;

  const panelBoardStatus = usePanelBoardValue();

  return (
    <Map // 지도를 표시할 Container
      center={site.center}
      style={{
        // 지도의 크기
        width: '100%',
        height: '100%',
      }}
      mapTypeId={1}
      level={4} // 지도의 확대 레벨
      disableDoubleClick={true}
      maxLevel={5}
      onCenterChanged={(e: any) => {
        const newLat = e.getCenter().getLat();
        const newLng = e.getCenter().getLng();

        const fixedLat =
          newLat > latMax ? latMax : newLat < latMin ? latMin : newLat;
        const fixedLng =
          newLng > lngMax ? lngMax : newLng < lngMin ? lngMin : newLng;

        e.setCenter(new kakao.maps.LatLng(fixedLat, fixedLng));
      }}
    >
      <div>
        {site.panelBoards.map((x, index) => (
          <CustomMarker
            zIndex={PanelBoardZIndex}
            index={index}
            selected={index === panelBoardIndex}
            image={{
              source: PanelBoardIcon(
                panelBoardStatus[index]?.onoff ?? '',
                index === panelBoardIndex
              ),
              width: 60,
              height: 90,
              scale: { base: 0.8, mouseOver: 0.9, selected: 0.9 },
            }}
            key={`PanelBoard-${x.position.lat}-${x.position.lng}`}
            position={x.position}
            onClick={() => panelBoardOnSelect(index)}
            onDoubleClick={() => panelBoardOnDoubleClick(index)}
          />
        ))}
      </div>
      <div>
        {site.lightPoles.map((x, index) => (
          <CustomMarker
            zIndex={PanelBoardZIndex - 1}
            index={index}
            selected={index === lightPostIndex}
            image={{
              source:
                lightPoleStatus[index].status === 'ON'
                  ? LightBulbIconOn
                  : lightPoleStatus[index].status === 'OFF'
                  ? LightBulbIconOff
                  : LightBulbIconError,
              width: 44,
              height: 44,
              scale: { base: 0.6, mouseOver: 0.8, selected: 0.8 },
            }}
            key={`LightBulb-${x.lat}-${x.lng}`}
            position={{ lat: x.lat, lng: x.lng }}
            onClick={() => lightPostOnSelect(index)}
            onDoubleClick={() => lightPostOnDoubleClick(index)}
          />
        ))}
      </div>
      <Polyline
        zIndex={PanelBoardZIndex - 2}
        path={site.lightPoles.map((x) => ({ lat: x.lat, lng: x.lng }))}
        strokeWeight={40} // 선의 두께 입니다
        strokeColor={'#0d6efd'} // 선의 색깔입니다
        strokeOpacity={0.7} // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle={'solid'} // 선의 스타일입니다
      />
    </Map>
  );
};
export default KakaoMapReact;
