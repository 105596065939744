import { createContext, useContext, useState } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
// import { fakeAuthProvider } from './FakeAuth';

export interface UserContext {
  userid: string;
  username: string;
  accessToken: string;
  roles: string[];
  homepage: string;
}

interface AuthContextType {
  user: UserContext;
  signin: (user: UserContext, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = useState<any>({
    userid: localStorage.getItem('user'),
    username: localStorage.getItem('username'),
    accessToken: localStorage.getItem('accessToken'),
    roles: JSON.parse(localStorage.getItem('roles') ?? '{}'),
  });

  let signin = (newUser: UserContext, callback: VoidFunction) => {
    setUser(newUser);
    localStorage.setItem('user', newUser.userid);
    localStorage.setItem('username', newUser.username);
    localStorage.setItem('accessToken', newUser.accessToken);
    localStorage.setItem('roles', JSON.stringify(newUser.roles));
    callback();
    // return fakeAuthProvider.signin(() => {
    //   callback();
    // });
  };

  let signout = (callback: VoidFunction) => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('username');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('roles');
    callback();
    // return fakeAuthProvider.signout(() => {
    //   callback();
    // });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

export function RequireAuth() {
  let auth = useAuth();
  let location = useLocation();

  if (
    !auth.user ||
    !auth.user.userid ||
    !auth.user.accessToken ||
    !(auth.user.roles && Array.isArray(auth.user.roles))
  ) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return <Outlet />;
}
