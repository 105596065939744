import { useState } from 'react';
import { toast } from 'react-toastify';
import OperationModeConfigView, {
  OperationModeConfig,
  StrMode2Num,
} from './panelboardconfig/OperationModeConfig';
import OnOffTimeConfigView, {
  OnOffTimeConfig,
} from './panelboardconfig/OnOffTimeConfig';
import ScheduleConfigView from './panelboardconfig/ScheduleConfig';
import { PanelBoardStatus, ScheduleItemType } from '../libs/Protocol/Protocol';
import { useMQTTContext } from 'context/MQTTProvider';
import { usePanelBoardState } from 'context/PanelBoardContext';
interface PanelBoardConfigViewProps {
  phoneNumber: string;
  onLoading: (loading: boolean) => void;
  onClose: () => void;
}

function PanelBoardConfigView({
  phoneNumber,
  onLoading,
  onClose,
}: PanelBoardConfigViewProps) {
  const [opmodeConfig, setOpmodeConfig] = useState<OperationModeConfig>({
    mode: '',
    dimming: 0,
  });
  const [OnOffTimeConfig, SetOnOffTimeConfig] = useState<OnOffTimeConfig>({
    onTime: '',
    onTimeCorrection: 0,
    onTimeDimming: 0,
    isOnTimeSunset: false,
    offTime: '',
    offTimeCorrection: 0,
    isOffTimeSunrise: false,
  });
  const [scheduleItems, setScheduleItems] = useState<ScheduleItemType[]>([]);
  const [statusList, setStatusList] = usePanelBoardState();
  const { api, isConnected } = useMQTTContext();

  function updateStatus(newStatus: PanelBoardStatus) {
    let newStatusList = [...statusList];
    const index = newStatusList.findIndex((x) => x.phoneNumber === phoneNumber);
    if (index) {
      newStatusList[index] = newStatus;
      setStatusList(newStatusList);
    }
  }

  if (!isConnected) {
    return <div>Connecting to Server...</div>;
  }

  return (
    <div>
      <OperationModeConfigView
        values={opmodeConfig}
        onChange={setOpmodeConfig}
        onSubmit={async () => {
          if (!opmodeConfig.mode) {
            toast.error('운영 모드를 설정해 주세요');
            return;
          }

          onLoading(true);
          try {
            const newStatus = await api.SetOperationMode({
              phoneno: phoneNumber,
              mode: StrMode2Num(opmodeConfig.mode),
              dim: opmodeConfig.dimming,
            });

            console.log('SetOperationMode newStatus', newStatus);
            updateStatus(newStatus);

            toast.success('운영 모드 설정 성공');
          } catch (e) {
            console.log(e);
            toast.error('운영 모드 설정 실패');
          }
          onLoading(false);
        }}
      />
      <div className='border mb-3' />
      <OnOffTimeConfigView
        values={OnOffTimeConfig}
        onChange={SetOnOffTimeConfig}
        onSubmit={async () => {
          if (OnOffTimeConfig.onTime === '' || OnOffTimeConfig.offTime === '') {
            toast.error('점등 소등 시각을 설정해주세요');
            return;
          }
          onLoading(true);
          try {
            const newStatus = await api.SetOnOffTime({
              phoneno: phoneNumber,
              onTime: OnOffTimeConfig.onTime,
              onTimeAjust: OnOffTimeConfig.onTimeCorrection,
              dim: OnOffTimeConfig.onTimeDimming,
              offTime: OnOffTimeConfig.offTime,
              offTimeAjust: OnOffTimeConfig.offTimeCorrection,
            });

            updateStatus(newStatus);

            toast.success('점등 소등 시각 설정 성공');
          } catch (e) {
            toast.error('점등 소등 시각 설정 실패');
          }
          onLoading(false);
        }}
      />
      <div className='border mb-3' />
      <ScheduleConfigView
        values={scheduleItems}
        onChange={setScheduleItems}
        onSubmit={async () => {
          onLoading(true);
          try {
            console.log('scheduleItems', scheduleItems);
            const newStatus = await api.SetSchedules({
              phoneno: phoneNumber,
              schedule: scheduleItems,
            });

            updateStatus(newStatus);

            toast.success('스케쥴 설정 성공');
          } catch (e) {
            console.log(e);
            toast.error('스케쥴 설정 실패');
          }
          onLoading(false);
        }}
      />
      <div className='border mb-3' />
      <div className='my-4 flex justify-content-center space-x-2'>
        <button
          className='btn btn-secondary col-2'
          onClick={() => {
            onClose();
          }}
        >
          닫기
        </button>
        <button
          className='btn btn-primary col-2'
          onClick={async () => {
            onLoading(true);
            try {
              const config = await api.GetConfig({ phoneno: phoneNumber });
              setOpmodeConfig({
                mode: config.operationMode ?? '',
                dimming: config.operationModeDimming ?? 0,
              });
              SetOnOffTimeConfig({
                onTime: config.onTime ?? '',
                onTimeCorrection: config.onTimeCorrection ?? 0,
                onTimeDimming: config.onTimeDimming ?? 0,
                isOnTimeSunset: config.onTime === '91:00' ? true : false,
                offTime: config.offTime ?? '',
                offTimeCorrection: config.offTimeCorrection ?? 0,
                isOffTimeSunrise: config.offTime === '92:00' ? true : false,
              });
              setScheduleItems(config.schedules ?? []);

              const index = statusList.findIndex(
                (x) => x.phoneNumber === phoneNumber
              );

              if (index !== -1) {
                let newStatus = { ...statusList[index] };

                newStatus.lastConnected = new Date();

                let newStatusList = [...statusList];
                newStatusList[index] = newStatus;
                setStatusList(newStatusList);
              }

              toast.success('설정을 불러왔습니다');
            } catch (e) {
              toast.error('설정을 불러오는데 실패하였습니다');
              console.log(e);
            }
            onLoading(false);
          }}
        >
          불러오기
        </button>
      </div>
    </div>
  );
}

export default PanelBoardConfigView;
