import { useId } from 'react';
import { Button } from 'react-bootstrap';
import LabeledRange from './LabeledRange';
import IconCorrection from '../../assets/icons/settings_black_24dp.svg';
import IconDimming from '../../assets/icons/light_mode_black_24dp.svg';
// import TimePicker from 'react-time-picker';

interface OnOffTimeProps {
  label: string;
  sunRiseSetLabel: string;
  value: any;
  onChangeValue: (value: string) => void;
  checked: boolean;
  onChangeChecked: (checked: boolean) => void;
}

function OnOffTime({
  label,
  sunRiseSetLabel,
  value,
  onChangeValue,
  checked,
  onChangeChecked,
}: OnOffTimeProps) {
  const id = useId();
  return (
    <div className='input-group flex-nowrap fs-6'>
      <span className='input-group-text'>{label} 시각</span>
      {!checked ? (
        <input
          type='time'
          className='form-control text-center'
          style={{ minWidth: '200px' }}
          min={'00:00'}
          max={'24:59'}
          value={value}
          onChange={(e) => onChangeValue(e.target.value)}
        />
      ) : (
        <label
          className='form-control text-center text-nowrap w-100'
          style={{ minWidth: '200px' }}
        >
          {sunRiseSetLabel} 시각
        </label>
      )}
      <div className='input-group-text'>
        <input
          className='form-check-input mt-0'
          type='checkbox'
          id={id}
          checked={checked}
          onChange={(e) => {
            onChangeChecked(e.target.checked);
          }}
        />
        <label className='form-check-label ms-2' htmlFor={id}>
          {sunRiseSetLabel}
        </label>
      </div>
    </div>
  );
}

export interface OnOffTimeConfig {
  onTime: string;
  onTimeCorrection: number;
  onTimeDimming: number;
  isOnTimeSunset: boolean;
  offTime: string;
  offTimeCorrection: number;
  isOffTimeSunrise: boolean;
}

interface OnOffTimeConfigViewProps {
  values: OnOffTimeConfig;
  onChange: (config: OnOffTimeConfig) => void;
  onSubmit: () => void;
}

function OnOffTimeConfigView({
  values,
  onChange,
  onSubmit,
}: OnOffTimeConfigViewProps) {
  return (
    <div className='px-4 py-2'>
      <div className='fs-5 fw-bold'>점등 소등 시각</div>
      <div className='d-flex flex-column mt-3'>
        <div className='d-flex'>
          <OnOffTime
            label='점등'
            sunRiseSetLabel='일몰'
            value={values.onTime}
            onChangeValue={(value) =>
              onChange({
                ...values,
                onTime: value,
              })
            }
            checked={values.isOnTimeSunset}
            onChangeChecked={(checked) =>
              onChange({
                ...values,
                isOnTimeSunset: checked,
                onTime: checked ? '91:00' : '',
              })
            }
          />

          <LabeledRange
            disabled={!values.isOnTimeSunset}
            className='ms-3 w-100'
            label={<img src={IconCorrection} alt='' />}
            min={-60}
            max={60}
            value={values.onTimeCorrection}
            onChange={(value) =>
              onChange({ ...values, onTimeCorrection: value })
            }
          />

          <LabeledRange
            className='ms-3 w-100'
            label={<img src={IconDimming} alt='' />}
            min={0}
            max={100}
            value={values.onTimeDimming}
            onChange={(value) => onChange({ ...values, onTimeDimming: value })}
          />
        </div>
        <div className='d-flex mt-3'>
          <OnOffTime
            label='소등'
            sunRiseSetLabel='일출'
            value={values.offTime}
            onChangeValue={(value) =>
              onChange({
                ...values,
                offTime: value,
              })
            }
            checked={values.isOffTimeSunrise}
            onChangeChecked={(checked) =>
              onChange({
                ...values,
                isOffTimeSunrise: checked,
                offTime: checked ? '92:00' : '',
              })
            }
          />
          <LabeledRange
            className='ms-3 w-100'
            label={<img src={IconCorrection} alt='' />}
            min={-60}
            max={60}
            value={values.offTimeCorrection}
            onChange={(value) =>
              onChange({ ...values, offTimeCorrection: value })
            }
            disabled={!values.isOffTimeSunrise}
          />
          <div className='ms-3 w-100'></div>
        </div>
      </div>
      <div className='d-flex justify-content-center mt-3'>
        <Button className='col-1' onClick={onSubmit}>
          적용
        </Button>
      </div>
    </div>
  );
}

export default OnOffTimeConfigView;
