import { Outlet, Link, useLocation } from 'react-router-dom';
import { SiteProvider } from 'context/SiteProvider';

import Header from 'components/Header';
import BackgroundImage from 'assets/sisul_20200903_1.jpg';

const LinkBase =
  'w-full h-8 flex justify-center items-center text-center text-black rounded-t border-r border-y';
const InactiveLink = LinkBase + ' bg-gray-100 hover:bg-gray-200';
const ActiveLink = LinkBase + ' bg-gray-300 hover:bg-gray-400';

export default function SportsComplexLayout() {
  const loc = useLocation();

  return (
    <SiteProvider>
      <div className='w-full h-screen bg-slate-100'>
        <Header title='연천종합운동장 조명 관제시스템' />
        <img
          className='w-full h-1/6 lg:h-96 object-cover'
          src={BackgroundImage}
          alt=''
        />
        <div className='flex justify-center mx-1 md:mx-2 mt-2 md:mt-4'>
          <div className='w-full lg:w-[600px] space-y-2'>
            <div className='flex'>
              <Link
                to='/'
                className={loc.pathname === '/' ? ActiveLink : InactiveLink}
              >
                상태
              </Link>
              <Link
                to='/config'
                className={
                  loc.pathname === '/config' ? ActiveLink : InactiveLink
                }
              >
                점소등시각 설정
              </Link>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </SiteProvider>
  );
}
