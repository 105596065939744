import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './tailwind.css';

import { RecoilRoot } from 'recoil';
import { AuthProvider } from './auth/AuthProvider';
import { MQTTProvider } from 'context/MQTTProvider';

console.log(process.env.REACT_APP_NAME, process.env.REACT_APP_VERSION);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RecoilRoot>
        <MQTTProvider>
          <App />
        </MQTTProvider>
      </RecoilRoot>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
