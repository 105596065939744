import { Button } from 'react-bootstrap';
import LabeledRange from './LabeledRange';
import IconDimming from '../../assets/icons/light_mode_black_24dp.svg';
import { ScheduleItemType } from '../../libs/Protocol/Protocol';

interface ScheduleItemProps {
  time: string;
  onChangeTime: (time: string) => void;
  dimming: number;
  onChangeDimming: (dimming: number) => void;
  onRemove: () => void;
}

function ScheduleItem({
  time,
  onChangeTime,
  dimming,
  onChangeDimming,
  onRemove,
}: ScheduleItemProps) {
  return (
    <div className='d-flex py-1 w-100 fs-6'>
      <div className='input-group d-flex flex-nowrap w-50'>
        <span className='input-group-text h-100'>시각</span>
        <input
          type='time'
          className='form-control text-center'
          min={'00:00'}
          max={'24:59'}
          value={time}
          onChange={(e) => onChangeTime(e.target.value)}
        />
      </div>
      <LabeledRange
        className='ms-3'
        label={<img src={IconDimming} alt='' />}
        min={0}
        max={100}
        value={dimming}
        onChange={(value) => onChangeDimming(value)}
      />
      <button
        className='btn-close bg-secondary ms-3 w-25 h-75'
        onClick={() => {
          onRemove();
        }}
      />
    </div>
  );
}

export interface ScheduleConfigViewProps {
  values: ScheduleItemType[];
  onChange: (values: ScheduleItemType[]) => void;
  onSubmit: () => void;
}

function ScheduleConfigView({
  values,
  onChange,
  onSubmit,
}: ScheduleConfigViewProps) {
  return (
    <div className='px-4 py-2'>
      <div className='fs-5 fw-bold'>스케쥴 설정</div>
      <div className='d-flex flex-wrap mt-3'>
        {values.map((x, index) => (
          <ScheduleItem
            key={index + 1}
            time={x.ScheduleTime}
            onChangeTime={(time) => {
              let newItems = [...values];
              newItems[index].ScheduleTime = time;
              onChange(newItems);
            }}
            dimming={x.Dim}
            onChangeDimming={(dimming) => {
              let newItems = [...values];
              newItems[index].Dim = dimming;
              onChange(newItems);
            }}
            onRemove={() => {
              let newItems = [...values];
              newItems.splice(index, 1);
              onChange(newItems);
            }}
          />
        ))}
        <div className='d-flex w-100'>
          {values.length < 4 ? (
            <button
              className='btn btn-outline-primary w-100'
              onClick={() => {
                let newItems = [...values];
                newItems.push({ ScheduleTime: '', Dim: 0 });
                onChange(newItems);
              }}
            >
              스케쥴 추가
            </button>
          ) : null}
        </div>
      </div>
      <div className='row justify-content-center mt-3'>
        <Button className='col-1' onClick={onSubmit}>
          적용
        </Button>
      </div>
    </div>
  );
}

export default ScheduleConfigView;
