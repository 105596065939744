import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { usePanelBoardValue } from '../context/PanelBoardContext';

const commStatusEventTimeout = 1000 * 60 * 60 * 24;

interface DisplayValueProps {
  label: string;
  value: JSX.Element | string | number;
}

function DisplayValue50({ label, value }: DisplayValueProps): JSX.Element {
  return (
    <div className='d-flex mx-2 my-1 border-bottom w-50'>
      <div className='ms-2 col-2 fw-bold text-truncate w-25'>{label}</div>
      <div className='ms-2 text-truncate'>{value}</div>
    </div>
  );
}

function DisplayValue100({ label, value }: DisplayValueProps): JSX.Element {
  return (
    <div className='d-flex mx-2 my-1 border-bottom w-100'>
      <div className='ms-2 col-2 fw-bold text-truncate w-25'>{label}</div>
      <div className='ms-2 text-truncate'>{value}</div>
    </div>
  );
}

function GetColor(status: string): string {
  switch (status) {
    case '정상':
      return 'Black';
    case '-':
      return 'Black';
    default:
      return 'Red';
  }
}

function ColoredValue(status?: string): JSX.Element {
  return <div style={{ color: GetColor(status ?? '-') }}>{status ?? '-'}</div>;
}

interface PanelBoardDetailProps {
  phoneNumber: string;
  location: string;
}

function PanelBoardDetail({ phoneNumber, location }: PanelBoardDetailProps) {
  const statusList = usePanelBoardValue();
  let status = statusList.find((x) => x.phoneNumber === phoneNumber) ?? {};
  const [commStatus, setCommStatus] = useState('-');

  function GetCommStatus(lastConnected: Date | undefined) {
    if (lastConnected) {
      if (
        new Date().getTime() - new Date(lastConnected).getTime() >
        commStatusEventTimeout
      ) {
        setCommStatus('이벤트');
      } else {
        setCommStatus('정상');
      }
    } else {
      setCommStatus('-');
    }
  }

  useEffect(() => {
    GetCommStatus(status?.lastConnected);
  }, [status?.lastConnected]);

  useInterval(() => {
    GetCommStatus(status?.lastConnected);
  }, 1000);

  return (
    <div
      className='d-flex h-auto'
      style={{
        fontFamily: 'NanumSquare',
      }}
    >
      <div
        className='col-8'
        style={{
          border: 'solid',
          borderWidth: '0 0 0 3px',
          borderColor: '#a19f9f',
        }}
      >
        <div className='d-flex flex-row mx-2 my-1 border-bottom'>
          {/* <div className='ms-2 col-1 fw-bold text-truncate'>설치 위치</div>
          <div className='ms-5'>{location ?? '-'}</div> */}
          <div className='d-flex w-50'>
            <div className='ms-2 fw-bold text-truncate w-25'>설치 위치</div>
            <div className='ms-1 text-truncate'>{location ?? '-'}</div>
          </div>
        </div>

        <div className='d-flex flex-row'>
          <DisplayValue50 label='정전' value={ColoredValue(status.blackout)} />
          <DisplayValue50 label='점등 시간' value={status.onTime ?? '--:--'} />
        </div>
        <div className='d-flex flex-row'>
          <DisplayValue50 label='문열림' value={status.doorOpened ?? '-'} />
          <DisplayValue50 label='소등 시간' value={status.offTime ?? '--:--'} />
        </div>

        <div className='d-flex flex-row'>
          <DisplayValue50 label='통신상태' value={ColoredValue(commStatus)} />
          <DisplayValue50
            label='운영모드'
            value={status.operationMode ?? '-'}
          />
        </div>
      </div>
      <div
        className='col-4'
        style={{
          border: 'solid',
          borderWidth: '0 0 0 3px',
          borderColor: '#a19f9f',
        }}
      >
        <div className='d-flex flex-row'>
          <DisplayValue100 label='전화번호' value={phoneNumber ?? '-'} />
        </div>
        <div className='d-flex flex-row'>
          <DisplayValue100 label='분기 수량' value={status.elbCount ?? '-'} />
        </div>
        <div className='d-flex flex-row'>
          <DisplayValue100
            label='ELB 트립'
            value={ColoredValue(status.elbTrip)}
          />
        </div>
        <div className='d-flex flex-row'>
          <DisplayValue100
            label='최종접속시간'
            value={status.lastConnected?.toLocaleString() ?? '-'}
          />
        </div>
      </div>
    </div>
  );
}

export default PanelBoardDetail;
