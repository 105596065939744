import {
  PanelBoardStatus,
  ParsePanelBoardStatus,
  PanelBoardConfig,
  ParsePanelBoardConfig,
  ScheduleItemType,
  Site,
} from '../libs/Protocol/Protocol';
import { MqttClient } from 'precompiled-mqtt';
import { createConnection } from './MQTTConnector';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';

const topicRequest = 'slc/request';
const topicResponse = 'slc/response';
const topicNotify = 'slc/notify';

// const jwtSecret = process.env.JWT_SECRET || 'PostIt_Secret';
const passSecret = process.env.PASS_SECRET || 'Streelamp_Manager_Pass_Secret';

interface RequestType {
  Authorization?: { Bearer?: string };
  method:
    | 'Login'
    | 'Logout'
    | 'SetOperateMode'
    | 'SetOnOff'
    | 'SetSchedule'
    | 'SetLightPole'
    | 'GetLogStatus'
    | 'GetConfig'
    | 'GetStatus'
    | 'GetSiteOne';
  msgid?: string;
  param: any;
}

interface ResponseType {
  result: 'MQTT_NOT_CONNECTED' | 'MQTT_TIMEOUT' | 'OK';
  response?: any | null;
}

interface LoginProps {
  id: string;
  pass: string;
}

export class API {
  constructor(client: MqttClient) {
    this._map = new Map();
    this._client = client;
    this._client.subscribe([topicResponse, topicNotify]);
    this._client.on('message', (topic: string, payload: Buffer) => {
      this.OnMessage(topic, payload);
    });
    this._client.on('connect', () => {
      this._onChangeConnectionState?.('CONNECTED');
      if (this._onConnectionChanged) {
        this._onConnectionChanged(true);
      }
    });
    this._client.on('disconnect', () => {
      this._onChangeConnectionState?.('DISCONNECTED');
      if (this._onConnectionChanged) {
        this._onConnectionChanged(false);
      }
    });
    this._client.on('close', () => {
      this._onChangeConnectionState?.('DISCONNECTED');
      if (this._onConnectionChanged) {
        this._onConnectionChanged(false);
      }
    });
    this._client.on('end', () => {
      this._onChangeConnectionState?.('DISCONNECTED');
      if (this._onConnectionChanged) {
        this._onConnectionChanged(false);
      }
    });
  }
  _client: MqttClient;
  _map: Map<string, (value: any | PromiseLike<any>) => void>;
  _onConnectionChanged?: (connected: boolean) => void;
  _onNotify?: (payload: any) => void;
  _onChangeConnectionState?: (state: string) => void;
  _connectionState:
    | 'CONNECTING'
    | 'CONNECTED'
    | 'DISCONNECTING'
    | 'DISCONNECTED' = 'DISCONNECTED';

  SetSubscription(params: SetSubscriptionProps) {
    this._onNotify = params.callback;
  }
  OnConnectionChanged(callback: (connected: boolean) => void) {
    this._onConnectionChanged = callback;
  }

  async OnMessage(topic: string, payload: Buffer) {
    if (topic === topicResponse) {
      const resp = JSON.parse(payload.toString());
      if (this._map.has(resp.msgid)) {
        const resolve = this._map.get(resp.msgid);
        if (resolve) {
          resolve(resp);
        }
      }
    } else if (topic === topicNotify) {
      this._onNotify?.(JSON.parse(payload.toString()));
    }
  }

  async Request(
    message: RequestType,
    timeout: number = 20 * 1000
  ): Promise<ResponseType> {
    if (this._client.connected === false) {
      return { result: 'MQTT_NOT_CONNECTED' };
    }

    const msgid = uuidv4();
    const p = new Promise<ResponseType>((resolve, reject) => {
      this._map.set(msgid, resolve);

      message.Authorization = {
        Bearer: localStorage.getItem('accessToken') ?? '',
      };

      message.msgid = msgid;
      this._client.publish(topicRequest, JSON.stringify(message), {
        retain: false,
        qos: 0,
      });

      setTimeout(() => {
        this._map.delete(msgid);
        reject({ result: 'MQTT_TIMEOUT' });
      }, timeout);
    });

    return await p;
  }

  async RequestRetry(
    message: RequestType,
    timeout: number = 20 * 1000,
    retry: number = 3
  ): Promise<ResponseType> {
    for (let i = 0; i < retry; i++) {
      try {
        const resp = await this.Request(message, timeout);

        if (resp.result === 'OK') {
          return Promise.resolve(resp);
        } else {
          return Promise.reject(resp);
        }
      } catch (e: any) {
        if (e.result === 'MQTT_NOT_CONNECTED') {
          return Promise.reject(e);
        }
      }
    }

    return Promise.reject({ result: 'MQTT_TIMEOUT' });
  }

  async Login(param: LoginProps): Promise<any> {
    const encryptedInfo = CryptoJS.AES.encrypt(
      JSON.stringify(param),
      passSecret
    ).toString();

    const resp = this.RequestRetry(
      {
        method: 'Login',
        param: encryptedInfo,
      },
      5 * 1000,
      1
    );

    return Promise.resolve(resp);
  }

  Logout(): void {
    //TODO: implemetation
  }

  async GetStatus(params: GetStatusProps): Promise<PanelBoardStatus> {
    try {
      const resp = await this.RequestRetry({
        method: 'GetStatus',
        param: params,
      });
      return Promise.resolve(ParsePanelBoardStatus(resp.response));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async GetLogStatus(params: GetStatusProps): Promise<PanelBoardStatus> {
    try {
      const resp = await this.RequestRetry({
        method: 'GetLogStatus',
        param: params,
      });
      return Promise.resolve(ParsePanelBoardStatus(resp.response));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async GetConfig(params: GetConfigProps): Promise<PanelBoardConfig> {
    try {
      const resp = await this.RequestRetry({
        method: 'GetConfig',
        param: params,
      });
      return Promise.resolve(ParsePanelBoardConfig(resp.response));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async SetOperationMode(
    params: SetOperationModeProps
  ): Promise<PanelBoardStatus> {
    try {
      const resp = await this.RequestRetry({
        method: 'SetOperateMode',
        param: params,
      });
      return Promise.resolve(ParsePanelBoardStatus(resp.response));
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async SetOnOffTime(params: SetOnOffTimeProps): Promise<PanelBoardStatus> {
    try {
      const resp = await this.RequestRetry({
        method: 'SetOnOff',
        param: params,
      });
      return Promise.resolve(ParsePanelBoardStatus(resp.response));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async SetSchedules(params: SetSchedulesProps): Promise<PanelBoardStatus> {
    try {
      const resp = await this.RequestRetry({
        method: 'SetSchedule',
        param: params,
      });
      return Promise.resolve(ParsePanelBoardStatus(resp.response));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async wait(timeout: number) {
    return new Promise((resolve) => setTimeout(resolve, timeout));
  }

  async SetLightPole(params: SetLightPoleProps): Promise<PanelBoardStatus> {
    try {
      await this.Request({
        method: 'SetLightPole',
        param: params,
      });
    } catch (e: any) {
      if (e.result === 'MQTT_NOT_CONNECTED') {
        return Promise.reject(e);
      }
    }

    await this.wait(3 * 1000);

    for (let i = 0; i < 3; i++) {
      try {
        const resp = await this.Request({
          method: 'GetStatus',
          param: { phoneno: params.phoneno },
        });
        if (resp.result === 'OK') {
          console.log(resp);
          const status = ParsePanelBoardStatus(resp.response);

          if (status.phoneNumber === params.phoneno && status.lightPoles) {
            const lightPoleStatus = status.lightPoles[params.pole - 1].status;

            if (params.onoff && lightPoleStatus === 'ON') {
              return Promise.resolve(status);
            } else if (!params.onoff && lightPoleStatus === 'OFF') {
              return Promise.resolve(status);
            }
          }
        }
      } catch (e: any) {
        if (e.result === 'MQTT_NOT_CONNECTED') {
          return Promise.reject(e);
        }
      }
    }

    console.log('SetLightPole end');
    return Promise.reject({ result: 'MQTT_TIMEOUT' });
  }

  async GetSiteOne(params: GetSiteOne): Promise<Site> {
    try {
      const resp = await this.RequestRetry({
        method: 'GetSiteOne',
        param: params,
      });

      const { name, description, location, panelBoards } = resp.response;

      return Promise.resolve({
        name: name,
        description: description,
        location: location,
        panelBoards: panelBoards,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export interface GetStatusProps {
  phoneno: string;
}

export interface GetConfigProps {
  phoneno: string;
}

export interface SetOperationModeProps {
  phoneno: string;
  mode: number;
  dim: number;
}

export interface SetOnOffTimeProps {
  phoneno: string;
  onTime: string;
  onTimeAjust: number;
  dim: number;
  offTime: string;
  offTimeAjust: number;
}

export interface SetSchedulesProps {
  phoneno: string;
  schedule: ScheduleItemType[];
}

export interface SetLightPoleProps {
  phoneno: string;
  pole: number;
  onoff: boolean;
  isAutoMode: boolean;
}

export interface GetSiteOne {
  name: string;
}

export interface SetSubscriptionProps {
  callback: (payload: any) => void;
}

export { createConnection };
export default API;
