import {
  Modal,
  ModalHeader,
  ModalBody,
  ButtonGroup,
  ToggleButton,
  Row,
  Col,
} from 'react-bootstrap';
import { PanelBoard } from '../sites/SiteModel';

interface DisplayValueProps {
  label: string;
  value: JSX.Element | string | number;
  left?: number;
}

function DisplayValue({ label, value, left }: DisplayValueProps) {
  left = left ?? 4;
  return (
    <Row className='m-0'>
      <Col
        className='m-0 fs-5'
        xs={left}
        style={{
          fontFamily: 'NanumSquareExtraBold',
          color: 'Grey',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </Col>
      <Col
        className='m-0'
        xs={12 - left}
        style={{
          fontFamily: 'NanumSquare',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </Col>
    </Row>
  );
}

interface LightOnOffProps {
  show: boolean;
  handleClose: () => void;
  panelBoard?: PanelBoard;
  phoneNumber: string;
  lightNumber: number;
  status?: string;
  handleChange: (status: string) => void;
}

export default function LightOnOff(props: LightOnOffProps) {
  return (
    <Modal
      className=''
      show={props.show}
      size='sm'
      centered
      style={{ zIndex: 1050 }}
    >
      <ModalHeader
        className='fs-4'
        closeButton
        onHide={props.handleClose}
        style={{ fontFamily: 'NanumSquareExtraBold' }}
      >
        등주 점등/소등
      </ModalHeader>
      <ModalBody>
        <div className='d-flex flex-column'>
          <DisplayValue
            label='분전함'
            value={props.panelBoard ? props.panelBoard.name : ''}
            left={5}
          />
          <DisplayValue label='전화번호' value={props.phoneNumber} left={5} />
          <DisplayValue label='등주번호' value={props.lightNumber} left={5} />
          <div className='d-flex justify-content-center mt-3 w-100'>
            <ButtonGroup className='flex-fill'>
              <ToggleButton
                key={0}
                id='radio-0'
                type='radio'
                variant='outline-primary'
                name='radio'
                value='ON'
                checked={
                  props.status === undefined
                    ? false
                    : props.status === 'ON'
                    ? true
                    : false
                }
                onChange={(e) => props.handleChange('ON')}
              >
                ON
              </ToggleButton>
              <ToggleButton
                key={1}
                id='radio-1'
                type='radio'
                variant='outline-primary'
                name='radio'
                value='OFF'
                checked={
                  props.status === undefined
                    ? false
                    : props.status === 'OFF'
                    ? true
                    : false
                }
                onChange={(e) => props.handleChange('OFF')}
              >
                OFF
              </ToggleButton>
            </ButtonGroup>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
