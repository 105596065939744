import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'auth/AuthProvider';
import { Col, Form, Button, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import IconTitle from 'assets/icons/title_icon.png';
import Logo from 'assets/logo.png';
import * as Yup from 'yup';
import { useMQTTContext } from 'context/MQTTProvider';
import { ModalSpinner } from 'components/ModalDialog';

export default function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  const { api, isConnected } = useMQTTContext();

  const [loading, setLoading] = useState(false);
  const state = location.state as { from: { pathname: string } };
  const from = state?.from?.pathname || '/';

  if (!isConnected) {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        <div className='flex flex-col items-center'>
          <div className='text-xl'>Connecting to Server...</div>
        </div>
      </div>
    );
  }

  const submit = async (values: { userid: string; password: string }) => {
    setLoading(true);
    try {
      const resp = await api.Login({
        id: values.userid,
        pass: values.password,
      });
      if (resp.result === 'OK' && resp.response.accessToken) {
        auth.signin(
          {
            userid: values.userid,
            username: resp.response.name,
            homepage: resp.response.homepage,
            accessToken: resp.response.accessToken,
            roles: resp.response.roles,
          },
          () => {
            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
            navigate(from, { replace: true });
          }
        );
        toast.success('로그인 성공');
      } else {
        if (resp.reason === 'ID_OR_PASSWORD_NOT_MATCH') {
          toast.error(<div>ID 또는 Password가 일치하지 않습니다</div>);
        } else {
          toast.error(<div>로그인을 실패하였습니다</div>);
        }
      }
    } catch (e: any) {
      if (e.reason === 'ID_OR_PASSWORD_NOT_MATCH') {
        toast.error(<div>ID 또는 Password가 일치하지 않습니다</div>);
      } else if (e.result === 'MQTT_TIMEOUT') {
        toast.error(<div>서버로부터 응답을 받지 못했습니다</div>);
      } else {
        toast.error(<div>로그인을 실패하였습니다</div>);
      }
    }
    setLoading(false);
  };

  return (
    <div className='d-flex flex-column justify-content-center vh-100 bg-light'>
      <ModalSpinner show={loading} />
      <div className='bg-white w-auto m-auto'>
        <div className='d-flex flex-row align-items-center border-bottom border-2 px-5 py-3'>
          <img src={IconTitle} alt='' />
          <div
            className='ms-3 fs-1'
            style={{ fontFamily: 'NanumSquareExtraBold' }}
          >
            연천군 가로등 관제시스템
          </div>
        </div>

        <Formik
          initialValues={{ userid: '', password: '' }}
          onSubmit={submit}
          validationSchema={Yup.object().shape({
            userid: Yup.string()
              .min(6, '6자리 이상이어야 합니다')
              .max(16, '16자리 이하이어야 합니다.')
              .required('필수 항목입니다.'),
            password: Yup.string()
              .min(8, '8자리 이상이어야 합니다')
              .max(16, '16자리 이하이어야 합니다.')
              // .matches(
              //   /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/,
              //   "영문,숫자포함 8~16자리 이어야 합니다."
              // )
              .required('필수 항목입니다.'),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form
              className='mt-4'
              style={{ fontFamily: 'NanumSquare' }}
              onSubmit={handleSubmit}
            >
              <Form.Group controlId='userid' as={Row} className='g-0'>
                <Row className='g-0 px-3'>
                  <Form.Label column className='text-end fw-bold px-2'>
                    ID
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      name='userid'
                      placeholder='사용자 ID를 입력하세요.'
                      value={values.userid}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.userid && !errors.userid}
                      isInvalid={touched.userid && errors.userid ? true : false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Form.Control.Feedback type='invalid' className='d-flex'>
                    <div className='w-100 text-end'>{errors.userid}</div>
                  </Form.Control.Feedback>
                </Row>
              </Form.Group>
              <Form.Group
                controlId='formGroupPassword'
                as={Row}
                className='mt-2 g-0'
              >
                <Row className='g-0 px-3'>
                  <Form.Label
                    column
                    className='text-nowrap text-end fw-bold px-2'
                  >
                    Password
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type='password'
                      name='password'
                      placeholder='암호를 입력하세요.'
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.password && !errors.password}
                      isInvalid={
                        touched.password && errors.password ? true : false
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Form.Control.Feedback type='invalid' className='d-flex'>
                    <div className='w-100 text-end'>{errors.password}</div>
                  </Form.Control.Feedback>
                </Row>
              </Form.Group>
              <div className='d-flex justify-content-center my-5'>
                <Button
                  className='w-25'
                  variant='primary'
                  type='submit'
                  disabled={isSubmitting}
                >
                  로그인
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className='justify-content-center text-center m-5'>
        <img src={Logo} alt='' />
      </div>
    </div>
  );
}
