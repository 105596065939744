import { LightPole } from './SiteModel';

const phoneNumberPanel5 = '012-2004-4269';
const phoneNumberPanel6 = '012-2004-4476';
const phoneNumberPanel7 = '012-2004-8505';
const phoneNumberPanel8 = '012-2005-2476';

const YeoncheonGunmiriLightPoles: LightPole[] = [
  {
    number: 1,
    phoneNumber: phoneNumberPanel5,
    mac: '1D92',
    name: '074',
    lat: 37.9881909,
    lng: 126.9461349,
  },
  {
    number: 2,
    phoneNumber: phoneNumberPanel5,
    mac: '1D94',
    name: '075',
    lat: 37.9883871,
    lng: 126.9465131,
  },
  {
    number: 3,
    phoneNumber: phoneNumberPanel5,
    mac: '1D8F',
    name: '076',
    lat: 37.9884451,
    lng: 126.9467994,
  },
  {
    number: 4,
    phoneNumber: phoneNumberPanel5,
    mac: '1D97',
    name: '077',
    lat: 37.9885289,
    lng: 126.9471619,
  },
  {
    number: 5,
    phoneNumber: phoneNumberPanel5,
    mac: '2293',
    name: '078',
    lat: 37.9886481,
    lng: 126.9474647,
  },
  {
    number: 6,
    phoneNumber: phoneNumberPanel5,
    mac: '1CD9',
    name: '079',
    lat: 37.9887192,
    lng: 126.9477619,
  },
  {
    number: 7,
    phoneNumber: phoneNumberPanel5,
    mac: '1D90',
    name: '080',
    lat: 37.9888743,
    lng: 126.9481767,
  },
  {
    number: 8,
    phoneNumber: phoneNumberPanel5,
    mac: '228D',
    name: '081',
    lat: 37.9890125,
    lng: 126.9484734,
  },
  {
    number: 9,
    phoneNumber: phoneNumberPanel5,
    mac: '1D93',
    name: '082',
    lat: 37.9891081,
    lng: 126.9488063,
  },
  {
    number: 10,
    phoneNumber: phoneNumberPanel5,
    mac: '1D95',
    name: '083',
    lat: 37.9892518,
    lng: 126.9490744,
  },
  {
    number: 11,
    phoneNumber: phoneNumberPanel5,
    mac: '2395',
    name: '084',
    lat: 37.9893812,
    lng: 126.9494286,
  },
  {
    number: 12,
    phoneNumber: phoneNumberPanel5,
    mac: '2295',
    name: '085',
    lat: 37.9895835,
    lng: 126.949635,
  },
  {
    number: 13,
    phoneNumber: phoneNumberPanel5,
    mac: '2396',
    name: '086',
    lat: 37.9897555,
    lng: 126.9498678,
  },
  {
    number: 14,
    phoneNumber: phoneNumberPanel5,
    mac: '1D83',
    name: '087',
    lat: 37.9899477,
    lng: 126.9501248,
  },
  {
    number: 15,
    phoneNumber: phoneNumberPanel5,
    mac: '238B',
    name: '088',
    lat: 37.9902031,
    lng: 126.9504093,
  },
  {
    number: 16,
    phoneNumber: phoneNumberPanel5,
    mac: '2393',
    name: '089',
    lat: 37.9903478,
    lng: 126.9506328,
  },
  {
    number: 17,
    phoneNumber: phoneNumberPanel5,
    mac: '1CDB',
    name: '090',
    lat: 37.9905061,
    lng: 126.9508847,
  },
  {
    number: 18,
    phoneNumber: phoneNumberPanel5,
    mac: '1D8C',
    name: '091',
    lat: 37.9906656,
    lng: 126.9511496,
  },
  {
    number: 19,
    phoneNumber: phoneNumberPanel5,
    mac: '1D8B',
    name: '092',
    lat: 37.9907862,
    lng: 126.951399,
  },
  {
    number: 20,
    phoneNumber: phoneNumberPanel6,
    mac: '2391',
    name: '093',
    lat: 37.9909122,
    lng: 126.9517553,
  },
  {
    number: 21,
    phoneNumber: phoneNumberPanel6,
    mac: '238F',
    name: '094',
    lat: 37.9910337,
    lng: 126.9520689,
  },
  {
    number: 22,
    phoneNumber: phoneNumberPanel6,
    mac: '1CD3',
    name: '095',
    lat: 37.9911769,
    lng: 126.9523505,
  },
  {
    number: 23,
    phoneNumber: phoneNumberPanel6,
    mac: '1CD7',
    name: '096',
    lat: 37.9913228,
    lng: 126.9526365,
  },
  {
    number: 24,
    phoneNumber: phoneNumberPanel6,
    mac: '1CCF',
    name: '097',
    lat: 37.991473,
    lng: 126.9529062,
  },
  {
    number: 25,
    phoneNumber: phoneNumberPanel6,
    mac: '1D85',
    name: '098',
    lat: 37.9916571,
    lng: 126.9531478,
  },
  {
    number: 26,
    phoneNumber: phoneNumberPanel6,
    mac: '2294',
    name: '099',
    lat: 37.9918408,
    lng: 126.9533744,
  },
  {
    number: 27,
    phoneNumber: phoneNumberPanel6,
    mac: '1D86',
    name: '100',
    lat: 37.9920409,
    lng: 126.9536505,
  },
  {
    number: 28,
    phoneNumber: phoneNumberPanel6,
    mac: '1CDC',
    name: '101',
    lat: 37.9922314,
    lng: 126.9538816,
  },
  {
    number: 29,
    phoneNumber: phoneNumberPanel6,
    mac: '1D91',
    name: '102',
    lat: 37.9924478,
    lng: 126.954104,
  },
  {
    number: 30,
    phoneNumber: phoneNumberPanel6,
    mac: '2389',
    name: '103',
    lat: 37.992638,
    lng: 126.9543024,
  },
  {
    number: 31,
    phoneNumber: phoneNumberPanel6,
    mac: '2398',
    name: '104',
    lat: 37.9928796,
    lng: 126.9544965,
  },
  {
    number: 32,
    phoneNumber: phoneNumberPanel6,
    mac: '1D8D',
    name: '105',
    lat: 37.9930794,
    lng: 126.9546868,
  },
  {
    number: 33,
    phoneNumber: phoneNumberPanel6,
    mac: '238C',
    name: '106',
    lat: 37.9932758,
    lng: 126.9549643,
  },
  {
    number: 34,
    phoneNumber: phoneNumberPanel6,
    mac: '1CD4',
    name: '107',
    lat: 37.9934505,
    lng: 126.9550809,
  },
  {
    number: 35,
    phoneNumber: phoneNumberPanel7,
    mac: '239B',
    name: '108',
    lat: 37.9941718,
    lng: 126.955778,
  },
  {
    number: 36,
    phoneNumber: phoneNumberPanel7,
    mac: '1D7F',
    name: '109',
    lat: 37.9943968,
    lng: 126.9559514,
  },
  {
    number: 37,
    phoneNumber: phoneNumberPanel7,
    mac: '2399',
    name: '110',
    lat: 37.994644,
    lng: 126.9562197,
  },
  {
    number: 38,
    phoneNumber: phoneNumberPanel7,
    mac: '238E',
    name: '111',
    lat: 37.9948857,
    lng: 126.9564696,
  },
  {
    number: 39,
    phoneNumber: phoneNumberPanel7,
    mac: '1CDA',
    name: '112',
    lat: 37.9950795,
    lng: 126.9566802,
  },
  {
    number: 40,
    phoneNumber: phoneNumberPanel7,
    mac: '239A',
    name: '113',
    lat: 37.9953069,
    lng: 126.9568325,
  },
  {
    number: 41,
    phoneNumber: phoneNumberPanel7,
    mac: '1D8E',
    name: '114',
    lat: 37.9955878,
    lng: 126.9570275,
  },
  {
    number: 42,
    phoneNumber: phoneNumberPanel7,
    mac: '1D8A',
    name: '115',
    lat: 37.9957346,
    lng: 126.9571657,
  },
  {
    number: 43,
    phoneNumber: phoneNumberPanel7,
    mac: '2394',
    name: '116',
    lat: 37.9959922,
    lng: 126.9573377,
  },
  {
    number: 44,
    phoneNumber: phoneNumberPanel7,
    mac: '1CD6',
    name: '117',
    lat: 37.9962055,
    lng: 126.9574812,
  },
  {
    number: 45,
    phoneNumber: phoneNumberPanel7,
    mac: '1CD8',
    name: '118',
    lat: 37.9964727,
    lng: 126.957648,
  },
  {
    number: 46,
    phoneNumber: phoneNumberPanel7,
    mac: '2390',
    name: '119',
    lat: 37.996769,
    lng: 126.957807,
  },
  {
    number: 47,
    phoneNumber: phoneNumberPanel7,
    mac: '1CCE',
    name: '120',
    lat: 37.996965,
    lng: 126.9579458,
  },
  {
    number: 48,
    phoneNumber: phoneNumberPanel7,
    mac: '1D82',
    name: '121',
    lat: 37.9972177,
    lng: 126.9580963,
  },
  {
    number: 49,
    phoneNumber: phoneNumberPanel7,
    mac: '2397',
    name: '122',
    lat: 37.9974694,
    lng: 126.9582574,
  },
  {
    number: 50,
    phoneNumber: phoneNumberPanel7,
    mac: '1CD5',
    name: '123',
    lat: 37.9977024,
    lng: 126.9584077,
  },
  {
    number: 51,
    phoneNumber: phoneNumberPanel7,
    mac: '1CDD',
    name: '124',
    lat: 37.9979377,
    lng: 126.9585904,
  },
  {
    number: 52,
    phoneNumber: phoneNumberPanel7,
    mac: '238A',
    name: '125',
    lat: 37.9981578,
    lng: 126.9587237,
  },
  {
    number: 53,
    phoneNumber: phoneNumberPanel7,
    mac: '239F',
    name: '126',
    lat: 37.9983904,
    lng: 126.958944,
  },
  {
    number: 54,
    phoneNumber: phoneNumberPanel8,
    mac: '1D84',
    name: '127',
    lat: 37.9985873,
    lng: 126.959076,
  },
  {
    number: 55,
    phoneNumber: phoneNumberPanel8,
    mac: '2392',
    name: '128',
    lat: 37.9987877,
    lng: 126.9592209,
  },
  {
    number: 56,
    phoneNumber: phoneNumberPanel8,
    mac: '238D',
    name: '129',
    lat: 37.9990224,
    lng: 126.9593544,
  },
  {
    number: 57,
    phoneNumber: phoneNumberPanel8,
    mac: '239E',
    name: '130',
    lat: 37.9992003,
    lng: 126.9595619,
  },
  {
    number: 58,
    phoneNumber: phoneNumberPanel8,
    mac: '1CDE',
    name: '131',
    lat: 37.9993699,
    lng: 126.9598684,
  },
  {
    number: 59,
    phoneNumber: phoneNumberPanel8,
    mac: '239C',
    name: '132',
    lat: 37.9995315,
    lng: 126.9601581,
  },
  {
    number: 60,
    phoneNumber: phoneNumberPanel8,
    mac: '2297',
    name: '133',
    lat: 37.9997216,
    lng: 126.960442,
  },
  {
    number: 61,
    phoneNumber: phoneNumberPanel8,
    mac: '1CCD',
    name: '134',
    lat: 37.9999689,
    lng: 126.9606417,
  },
  {
    number: 62,
    phoneNumber: phoneNumberPanel8,
    mac: '1D80',
    name: '135',
    lat: 38.0002277,
    lng: 126.9607381,
  },
  {
    number: 63,
    phoneNumber: phoneNumberPanel8,
    mac: '1D81',
    name: '136',
    lat: 38.0004812,
    lng: 126.9607874,
  },
  {
    number: 64,
    phoneNumber: phoneNumberPanel8,
    mac: '239D',
    name: '137',
    lat: 38.0007162,
    lng: 126.9607886,
  },
  {
    number: 65,
    phoneNumber: phoneNumberPanel8,
    mac: '1CD2',
    name: '138',
    lat: 38.0010227,
    lng: 126.9608307,
  },
  {
    number: 66,
    phoneNumber: phoneNumberPanel8,
    mac: '1CD1',
    name: '139',
    lat: 38.0012583,
    lng: 126.9608466,
  },
  {
    number: 67,
    phoneNumber: phoneNumberPanel8,
    mac: '1D87',
    name: '140',
    lat: 38.0014988,
    lng: 126.9609411,
  },
  {
    number: 68,
    phoneNumber: phoneNumberPanel8,
    mac: '2292',
    name: '141',
    lat: 38.0017785,
    lng: 126.9609707,
  },
  {
    number: 69,
    phoneNumber: phoneNumberPanel8,
    mac: '2291',
    name: '142',
    lat: 38.0020764,
    lng: 126.9612126,
  },
  {
    number: 70,
    phoneNumber: phoneNumberPanel8,
    mac: '1D88',
    name: '143',
    lat: 38.0022699,
    lng: 126.9614159,
  },
  {
    number: 71,
    phoneNumber: phoneNumberPanel8,
    mac: '1D96',
    name: '144',
    lat: 38.0024933,
    lng: 126.9616452,
  },
  {
    number: 72,
    phoneNumber: phoneNumberPanel8,
    mac: '1D89',
    name: '145',
    lat: 38.0027888,
    lng: 126.9619493,
  },
  {
    number: 73,
    phoneNumber: phoneNumberPanel8,
    mac: '2296',
    name: '146',
    lat: 38.0030008,
    lng: 126.9621655,
  },
  {
    number: 74,
    phoneNumber: phoneNumberPanel8,
    mac: '228F',
    name: '147',
    lat: 38.0031681,
    lng: 126.96239,
  },
  {
    number: 75,
    phoneNumber: phoneNumberPanel8,
    mac: '1CD0',
    name: '148',
    lat: 38.0033514,
    lng: 126.962677,
  },
  {
    number: 76,
    phoneNumber: phoneNumberPanel8,
    mac: '2290',
    name: '149',
    lat: 38.0034893,
    lng: 126.9629331,
  },
];

export default YeoncheonGunmiriLightPoles;
