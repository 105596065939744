import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { PanelBoardStatus } from '../libs/Protocol/Protocol';

const PanelBoardContext = atom<PanelBoardStatus[]>({
  key: 'PanelBoardContext',
  default: [],
});

export function usePanelBoardState() {
  return useRecoilState(PanelBoardContext);
}

export function usePanelBoardValue() {
  return useRecoilValue(PanelBoardContext);
}
