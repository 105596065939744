export interface ELBStatus {
  branch: number;
  enable: boolean | string;
  tripped: boolean | string;
  voltage: number | string;
  current: number | string;
}

export function ParseELBList(input: Array<any>): Array<ELBStatus> {
  let elbs: Array<ELBStatus> = [];

  input.forEach((e) => {
    const data = e.data;
    elbs.push({
      branch: parseInt(e.name.match(/(\d+)/)[0]) + 1,
      enable: data.사용여부,
      tripped: data.차단기트립,
      voltage: data.전압값,
      current: data.전류값,
    });
  });

  return elbs;
}

export interface LightPoleStatus {
  number: number;
  status: string;
}

export interface PanelBoardStatus {
  site?: string;
  phoneNumber?: string;
  onoff?: string;
  blackout?: string;
  onTime?: string;
  onTimeCorrection?: number;
  offTime?: string;
  offTimeCorrection?: number;
  operationMode?: string;
  doorOpened?: string;
  elbCount?: number;
  elbTrip?: string;
  lastConnected?: Date;
  elbs?: Array<ELBStatus>;
  lightPoles?: LightPoleStatus[];
}

export function ParsePanelBoardStatus({
  header,
  status,
  createdAt,
}: {
  header: any;
  status: any;
  createdAt?: string;
}): PanelBoardStatus {
  let result: PanelBoardStatus = {
    phoneNumber: header.전화번호,
    onoff: status.ONOFF,
    blackout: status.정전,
    onTime: status.점등시간,
    onTimeCorrection: status.점등보정시간,
    offTime: status.소등시간,
    offTimeCorrection: status.소등보정시간,
    doorOpened: status.문열림,
    operationMode: status.운영모드,
    elbCount: status.ELB수량,
    elbTrip: status.ELB트립,
    lastConnected: new Date(createdAt ?? Date.now()),
    elbs: ParseELBList(status.분기),
    lightPoles: [],
  };

  result.lightPoles = status.등주.map((x: any) => ({
    number: x.number,
    status: x.data.status,
  }));
  return result;
}

export interface ScheduleItemType {
  ScheduleTime: string;
  Dim: number;
}

export interface PanelBoardConfig {
  phoneNumber: string;
  onTime?: string;
  onTimeCorrection?: number;
  offTime?: string;
  offTimeCorrection?: number;
  onTimeDimming?: number;
  operationMode?: string;
  operationModeDimming?: number;
  schedules?: ScheduleItemType[];
}

export function ParsePanelBoardConfig({
  header,
  config,
}: {
  header: any;
  config: any;
}): PanelBoardConfig {
  let schedules: ScheduleItemType[] = [];

  if (config.스케줄) {
    config.스케줄.forEach((x: any) => {
      schedules.push({ ScheduleTime: x.시간, Dim: x.디밍 });
    });
  }

  let result: PanelBoardConfig = {
    phoneNumber: header.전화번호,
    onTime: config.점등시간,
    onTimeCorrection: config.점등보정시간,
    offTime: config.소등시간,
    offTimeCorrection: config.소등보정시간,
    onTimeDimming: config.점등디밍,
    operationMode: config.운영모드,
    operationModeDimming: config.운영디밍,
    schedules: schedules,
  };
  return result;
}

export interface Site {
  name: string;
  description: string;
  location: string;
  panelBoards: [
    {
      phoneNumber: string;
    }
  ];
}
