import { ELBStatus } from '../libs/Protocol/Protocol';

const defaultElbs: Array<ELBStatus> = [
  { branch: 1, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 2, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 3, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 4, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 5, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 6, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 7, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 8, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 9, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 10, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 11, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 12, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 13, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 14, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 15, enable: false, tripped: '-', voltage: '-', current: '-' },
  { branch: 16, enable: false, tripped: '-', voltage: '-', current: '-' },
];

interface ELBListProps {
  elbs?: Array<ELBStatus>;
}

function ELBListView(props: ELBListProps) {
  let elbs: Array<ELBStatus> = defaultElbs;
  if (props.elbs && props.elbs.length > 0) {
    elbs = props.elbs;
  }

  // console.log('elbs', elbs);

  return (
    <div
      className='d-flex flex-row'
      style={{
        fontFamily: 'NanumSquare',
      }}
    >
      {elbs.map((x) => (
        <div
          className='me-2 mt-2 rounded-2 w-100'
          key={x.branch}
          style={{
            fontFamily: 'NanumSquare',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            background: '#e6e6e6',
          }}
        >
          <div className='mx-2 d-flex flex-row fw-bold align-items-baseline'>
            <div className='fs-4'>{x.branch}</div>
            <div className=''>분기</div>
          </div>
          <div className='border-top border-white' />
          <div className='text-end mx-2'>{x.tripped}</div>
          <div className='text-end mx-2'>{x.voltage}</div>
          <div className='text-end mx-2'>{x.current}</div>
        </div>
      ))}
    </div>
  );
}

export default ELBListView;
