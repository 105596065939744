import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useMQTTContext } from './MQTTProvider';
import { Site } from 'libs/Protocol/Protocol';

interface SiteContextValue {
  siteData: Site | undefined;
}

const SiteContext = createContext<SiteContextValue | null>(null);

export const SiteProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const mqtt = useMQTTContext();
  const [siteData, setSiteData] = useState<Site>();

  useEffect(() => {
    async function fetchSite() {
      try {
        const site = await mqtt.api.GetSiteOne({
          name: 'YeoncheonSportsComplex',
        });
        setSiteData(site);
      } catch (e) {
        console.error(e);
      }
    }
    if (mqtt.isConnected) {
      fetchSite();
    }
  }, [mqtt.api, mqtt.isConnected]);

  const value = useMemo(() => ({ siteData }), [siteData]);

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};

export const useSiteContext = () => {
  const context = useContext(SiteContext);
  if (!context) {
    throw new Error('useSiteContext must be used within a SiteContext');
  }
  return context;
};
