interface OnOffTimeConfigProps {
  label: string;
  id: string;
  register: any;
}

export function OnOffTimeConfig({ label, id, register }: OnOffTimeConfigProps) {
  return (
    <div>
      <div className='grid grid-cols-5 items-center justify-between'>
        <label htmlFor='onTimeCorrection' className='col-span-2'>
          {label}
        </label>
        <input type='time' className='col-span-3' {...register('onTime')} />
      </div>
      <div className='grid grid-cols-5 items-center justify-between'>
        <label htmlFor='onTimeCorrection' className='col-span-2'>
          점등보정시간
        </label>
        <input type='range' className='col-span-3' {...register(id)} />
      </div>
    </div>
  );
}

interface FormLabelProps {
  label: string;
  disabled?: boolean;
}

export function FormLabelBold({ label, disabled = false }: FormLabelProps) {
  return (
    <div
      className={`${disabled ? 'text-gray-400' : ''} col-span-2`}
      style={{ fontFamily: 'NanumSquareBold' }}
    >
      {label}
    </div>
  );
}

interface OnOffTimeRangeProps {
  register: any;
  value: any;
  disabled: boolean;
}

export function OnOffTimeRange({
  register,
  value,
  disabled,
}: OnOffTimeRangeProps) {
  return (
    <div className='grid grid-cols-12 items-center gap-2'>
      <input
        type='range'
        className='col-span-9'
        min={-60}
        max={60}
        {...register}
        disabled={disabled}
      />
      <div
        className={`${disabled ? 'text-gray-400' : ''} col-span-3 text-center`}
      >
        {value}
      </div>
    </div>
  );
}
