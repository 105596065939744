import { useState, useCallback, useEffect } from 'react';
import { ModalSpinner } from 'components/ModalDialog';
import { toast } from 'react-toastify';
import {
  ParsePanelBoardStatus,
  PanelBoardStatus,
} from 'libs/Protocol/Protocol';
import {
  RenderOnOff,
  RenderOperationMode,
  LabeledValue,
} from 'components/sportscomplex';
import { useMQTTContext } from 'context/MQTTProvider';
import { useSiteContext } from 'context/SiteProvider';

export default function YeoncheonSportsComplex() {
  const [loading, setLoading] = useState(false);
  const { api, isConnected } = useMQTTContext();
  const site = useSiteContext();
  const phoneNumber = site.siteData?.panelBoards[0].phoneNumber;

  const [status, setStatus] = useState<PanelBoardStatus>();

  async function handleOnOff(opmode: string) {
    if (!phoneNumber) {
      return;
    }

    setLoading(true);
    try {
      const status = await api.SetOperationMode({
        phoneno: phoneNumber,
        mode: opmode === '강제점등' ? 1 : opmode === '강제소등' ? 2 : 0, //점등1 소등2
        dim: opmode === '강제점등' ? 100 : 0,
      });
      setStatus(status);
      toast.success(opmode + ' 성공');
    } catch (e) {
      toast.error(opmode + ' 실패');
    }
    setLoading(false);
  }

  const onMessage = useCallback(
    (notify: any) => {
      if (notify.result !== 'OK') {
        return;
      }

      if (notify.response.header.전화번호 === phoneNumber) {
        const status = ParsePanelBoardStatus(notify.response);
        setStatus(status);
      }
    },
    [phoneNumber]
  );
  api.SetSubscription({ callback: onMessage });

  useEffect(() => {
    async function fetchStatus() {
      if (!phoneNumber) {
        return;
      }
      try {
        const status = await api.GetLogStatus({ phoneno: phoneNumber });
        setStatus(status);
      } catch (e) {
        console.log(e);
      }
    }
    if (isConnected) {
      fetchStatus();
    }
  }, [api, isConnected, phoneNumber]);

  if (!isConnected || !phoneNumber) {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        <div className='flex flex-col items-center'>
          <div className='text-xl'>Connecting to Server...</div>
        </div>
      </div>
    );
  }

  return (
    <div className='space-y-2'>
      <ModalSpinner show={loading} />

      <RenderOnOff onoff={status?.onoff} />
      <RenderOperationMode opmode={status?.operationMode} />

      <LabeledValue label='점등시각' value={status?.onTime} />
      <LabeledValue label='소등시각' value={status?.offTime} />

      <LabeledValue
        label='최종접속시각'
        labelSize='text-lg'
        value={status?.lastConnected?.toLocaleString()}
      />

      <div className='mt-3 flex items-center justify-items-stretch space-x-2'>
        <button
          className='btn-primary w-full'
          onClick={() => {
            handleOnOff('강제점등');
          }}
        >
          강제점등
        </button>
        <button
          className='btn-secondary w-full'
          onClick={() => {
            handleOnOff('강제소등');
          }}
        >
          강제소등
        </button>
        <button
          className='btn-yellow w-full'
          onClick={() => {
            handleOnOff('자동운전');
          }}
        >
          자동운전
        </button>
      </div>
      <button
        className='btn-green w-full'
        onClick={async (e) => {
          setLoading(true);
          try {
            const status = await api.GetStatus({ phoneno: phoneNumber });
            setStatus(status);
            toast.success('상태를 업데이트 하는 데 성공하였습니다');
          } catch (e) {
            console.log(e);
            toast.error('상태를 업데이트 하는 데 실패하였습니다');
          }
          setLoading(false);
        }}
      >
        상태 업데이트
      </button>
    </div>
  );
}
