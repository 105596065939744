import { InputGroup } from 'react-bootstrap';
import FormRange from 'react-bootstrap/esm/FormRange';
import classNames from 'classnames';

interface LabeledRangeProps {
  className?: string;
  disabled?: boolean;
  label: string | JSX.Element;
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
}

function LabeledRange({
  className,
  disabled,
  label,
  min,
  max,
  value,
  onChange,
}: LabeledRangeProps) {
  return (
    <InputGroup
      className={classNames(className, 'd-flex flex-nowrap align-items-center')}
    >
      <InputGroup.Text className='h-100'>{label}</InputGroup.Text>
      <FormRange
        className='p-2 h-100 border'
        min={min}
        max={max}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        disabled={disabled}
      />
      <InputGroup.Text className='h-100' style={{ minWidth: '3em' }}>
        {value}
      </InputGroup.Text>
    </InputGroup>
  );
}

export default LabeledRange;
