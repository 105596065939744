import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth, RequireAuth } from './auth/AuthProvider';
import { ToastContainer } from 'react-toastify';
import LoginPage from './apps/common/LoginPage';
import GumiriMainPage from './apps/gumiri/GumiriMainPage';
import SportsComplexLayout from './apps/sportscomplex/SportsComplexLayout';
import SportsComplexMainPage from './apps/sportscomplex/SportsComplexMainPage';
import SportsComplexConfigPage from './apps/sportscomplex/SportsComplexConfigPage';

function IsSportsComplex(roles: string[]): boolean {
  return Array.isArray(roles) && roles?.includes('YeoncheonSportsComplex');
}

function App() {
  const auth = useAuth();
  const roles = auth.user?.roles;

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/health' element={<div>OK</div>} />
          <Route path='/login' element={<LoginPage />} />
          <Route element={<RequireAuth />}>
            {IsSportsComplex(roles) ? (
              <Route path='/' element={<SportsComplexLayout />}>
                <Route path='/' element={<SportsComplexMainPage />} />
                <Route path='/config' element={<SportsComplexConfigPage />} />
              </Route>
            ) : (
              <Route path='/' element={<GumiriMainPage />} />
            )}
          </Route>
          <Route path='*' element={<Navigate replace to='/' />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
