import { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { ModalSpinner } from 'components/ModalDialog';
import { useMQTTContext } from 'context/MQTTProvider';
import { useSiteContext } from 'context/SiteProvider';
import { FormLabelBold, OnOffTimeRange } from './SportsComplexConfig';

const SUNSET_TIME = '91:00';
const SUNRISE_TIME = '92:00';

export default function SportsComplexConfigPage() {
  const { api, isConnected } = useMQTTContext();
  const [loading, setLoading] = useState(false);
  const [isSunset, setIsSunset] = useState(false);
  const [isSunrise, setIsSunrise] = useState(false);

  const site = useSiteContext();
  const phoneNumber = site.siteData?.panelBoards[0].phoneNumber;

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      onTime: '-',
      offTime: '-',
      onTimeCorrection: 0,
      offTimeCorrection: 0,
    },
  });
  const onTimeCorrection = watch('onTimeCorrection');
  const offTimeCorrection = watch('offTimeCorrection');

  async function onClickLoad() {
    if (!phoneNumber) {
      return;
    }

    setLoading(true);
    try {
      const resp = await api.GetConfig({ phoneno: phoneNumber });
      setValue('onTime', resp.onTime || '07:00');
      setValue('onTimeCorrection', resp.onTimeCorrection || 0);
      setValue('offTime', resp.offTime || '20:00');
      setValue('offTimeCorrection', resp.offTimeCorrection || 0);

      setIsSunrise(resp.offTime === SUNRISE_TIME);
      setIsSunset(resp.onTime === SUNSET_TIME);

      toast.success('설정을 불러오는데 성공했습니다');
      console.log(resp);
    } catch (e) {
      toast.error('설정을 불러오는데 실패했습니다');
      console.error(e);
    }
    setLoading(false);
  }

  async function onSubmit(value: any) {
    if (!phoneNumber) {
      return;
    }

    if (!isSunset && (value.onTime === '' || value.onTime === '-')) {
      toast.warn('점등 시각을 설정해주세요');
      return;
    }

    if (!isSunrise && (value.offTime === '' || value.offTime === '-')) {
      toast.warn('소등 시각을 설정해주세요');
      return;
    }

    setLoading(true);
    try {
      await api.SetOnOffTime({
        phoneno: phoneNumber,
        onTime: isSunset ? SUNSET_TIME : value.onTime,
        onTimeAjust: value.onTimeCorrection,
        offTime: isSunrise ? SUNRISE_TIME : value.offTime,
        offTimeAjust: value.offTimeCorrection,
        dim: 100,
      });
      toast.success('설정 저장 성공');
    } catch (e) {
      toast.error('설정 불러오기 성공');
      console.error(e);
    }
    setLoading(false);
  }

  if (!isConnected) {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        <div className='flex flex-col items-center'>
          <div className='text-xl'>서버에 연결하는 중...</div>
        </div>
      </div>
    );
  }

  if (!site || !site.siteData) {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        <div className='flex flex-col items-center'>
          <div className='text-xl'>현장 정보를 불러오는 중...</div>
        </div>
      </div>
    );
  }

  return (
    <div className='space-y-4 p-2'>
      <ModalSpinner show={loading} />
      <form className='space-y-4 text-lg' onSubmit={handleSubmit(onSubmit)}>
        <div className=''>
          <FormLabelBold label='점등시각' />
          <div className='grid grid-cols-12 items-center gap-2'>
            {isSunset ? (
              <div className='col-span-9 border rounded text-center'>
                일몰시각
              </div>
            ) : (
              <input
                type='time'
                className='col-span-9 border rounded text-center'
                {...register('onTime')}
              />
            )}
            <div className='col-span-3 flex justify-center space-x-2 rounded'>
              <input
                type='checkbox'
                id='isSunset'
                checked={isSunset}
                onChange={(e) => setIsSunset(e.target.checked)}
              />
              <label htmlFor='isSunset'>일몰</label>
            </div>
          </div>
        </div>
        <div className=''>
          <FormLabelBold label='점등보정시간' disabled={!isSunset} />
          <OnOffTimeRange
            register={register('onTimeCorrection')}
            value={onTimeCorrection}
            disabled={!isSunset}
          />
        </div>

        {/****************************************/}

        <div className=''>
          <FormLabelBold label='소등시각' />
          <div className='grid grid-cols-12 gap-2'>
            {isSunrise ? (
              <div className='col-span-9 border rounded text-center'>
                일출시각
              </div>
            ) : (
              <input
                type='time'
                className='col-span-9 border rounded text-center'
                {...register('offTime')}
              />
            )}
            <div className='col-span-3 flex justify-center space-x-2'>
              <input
                type='checkbox'
                id='isSunrise'
                checked={isSunrise}
                onChange={(e) => setIsSunrise(e.target.checked)}
              />
              <label htmlFor='isSunrise'>일출</label>
            </div>
          </div>
        </div>
        <div className=''>
          <FormLabelBold label='소등보정시간' disabled={!isSunrise} />
          <OnOffTimeRange
            register={register('offTimeCorrection')}
            value={offTimeCorrection}
            disabled={!isSunrise}
          />
        </div>
        <div className='flex space-x-2 mt-5'>
          <button className='btn-primary w-full' type='submit'>
            저장하기
          </button>
          <button
            type='button'
            className='btn-green w-full'
            onClick={async () => {
              await onClickLoad();
            }}
          >
            불러오기
          </button>
        </div>
      </form>
    </div>
  );
}
